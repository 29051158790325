import { showForAF } from '../helpers/envServer';
import airFreightReportFieldMapping from './fieldMappings/airFreightReportFieldMapping';
import airFreightReportFieldProperties from './fieldProperties/airFreightReportFieldProperties';

export const airFreightOverrideReportHeader = [
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_PONUMBER],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_POITEMNUMBER],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_OGAC],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_INITIAL_GAC],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_GAC],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_GRC],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_CURRENT_NAF],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_CURRENT_FAF],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_COMMENTS],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_STATUS],
  airFreightReportFieldProperties[
    airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ATTACHMENTS_UPLOAD
  ],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ATTACHMENTS]
];

export const airFreightReportHiddenHeader = [
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OVERRIDE_ELIGIBLE]
];

export const airFreightReportPdfExportHeaderFileds = [
  airFreightReportFieldMapping.AIR_FREIGHT_VENDOR,
  airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_CODE,
  airFreightReportFieldMapping.AIR_FREIGHT_LAUNCH,
  airFreightReportFieldMapping.AIR_FREIGHT_PO_NUMBER,
  airFreightReportFieldMapping.AIR_FREIGHT_ITEM_NUMBER,
  airFreightReportFieldMapping.AIR_FREIGHT_SHIPPING_TYPE,
  airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_SHIP_TO,
  airFreightReportFieldMapping.AIR_FREIGHT_PLANT_CODE,
  airFreightReportFieldMapping.AIR_FREIGHT_DESTINATION_COUNTRY_NAME,
  airFreightReportFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE,
  airFreightReportFieldMapping.AIR_FREIGHT_ITEM_QTY,
  airFreightReportFieldMapping.AIR_FREIGHT_NAF,
  airFreightReportFieldMapping.AIR_FREIGHT_FAF,
  airFreightReportFieldMapping.AIR_FREIGHT_OGAC,
  airFreightReportFieldMapping.AIR_FREIGHT_CURRENT_GAC_DATE,
  airFreightReportFieldMapping.AIR_FREIGHT_GAC_REASON_CODE,
  airFreightReportFieldMapping.AIR_FREIGHT_APPROVER_USER_NAME,
  airFreightReportFieldMapping.AIR_FREIGHT_LAST_UPDATED_TS
];

const airFreightReportHeader = [
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_PO_NUMBER],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_ITEM_NUMBER],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_DPOM_LINE_ITEM_STATUS],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_VENDOR],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_DIVISION_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_PRODUCT_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_ITEM_QTY],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_NAF],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_FAF],
  (showForAF) && airFreightReportFieldProperties[
    airFreightReportFieldMapping.AIR_FREIGHT_REQUEST_STATUS
  ],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_INITIAL_GAC_DATE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OGAC],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_CURRENT_GAC_DATE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_GAC_REASON_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_GAC_USECASE_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_SEASON_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_SEASON_YEAR],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_ORG_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_CURRENT_EVENT],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_NEXT_EVENT],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_ORDER_REASON_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_PMO_DEC_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_LAUNCH],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_MIDSOLE_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_OUTSOLE_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_CATEGORY_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_UNIT_OF_MEASURE_CODE],
  airFreightReportFieldProperties[airFreightReportFieldMapping.AIR_FREIGHT_SUB_CATEGORY_CODE],
  showForAF && airFreightReportFieldProperties[
    airFreightReportFieldMapping.AIR_FREIGHT_DESTINATION_COUNTRY_CODE
  ],
  showForAF && airFreightReportFieldProperties[
    airFreightReportFieldMapping.AIR_FREIGHT_PLANT_CODE
  ],
  showForAF && airFreightReportFieldProperties[
    airFreightReportFieldMapping.AIR_FREIGHT_CUSTOMER_SHIP_TO
  ]
].filter((el) => el !== false);
export default airFreightReportHeader;
